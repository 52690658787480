<template>
  <v-autocomplete v-model="selectedCampaign" :items="items" :loading="loading" item-text="name" item-value="campaign_id" :label="label" @change="changed()" 
    prepend-icon="campaign" :required="mandatory" :rules="[v => !mandatory || !!v || label + ' is required']"
    :disabled="!accountID || disabled" return-object :clearable="!mandatory">
    <template v-slot:append-outer>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-dots-vertical</v-icon>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-title @click="refresh()">Refresh campaigns</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="showAdGroupRefresh">
              <v-list-item-title @click="refreshAdGroups()">Refresh ad groups</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  // uses return-object so that campaign name can be used by caller
  // account selector does not need this, since accounts are in Vuex and name can be taken from there
  name: 'BingCampaignAutoComplete',

  props: {
    value: { type: Object, required: false }, // not required since passing a nullable object
    accountID: { type: Number, required: true },
    label: { type: String, required: false, default: 'Campaign' },
    mandatory: { type: Boolean, required: false, default: true },
    disabled: { type: Boolean, required: false, default: false },
    showAdGroupRefresh: { type: Boolean, required: false, default: false },
    getParams: { type: String, required: false, default: '' },
  },

  data () {
    return {
      items: [],
      loading: false,
    }
  },

  computed: {
    getEndpoint () {
      return '/a/bing/accounts/' + this.accountID + '/campaigns?xsort=name&xfields=campaign_id,name&xper_page=5000' + this.getParams
    },

    refreshAdGroupsEndpoint () {
      return '/a/bing/accounts/' + this.accountID + '/refresh-ad-groups'
    },

    refreshEndpoint () {
      return '/a/bing/accounts/' + this.accountID + '/refresh-campaigns'
    },

    // v-model
    selectedCampaign: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },

  watch: {
    accountID: {
      handler () {
        this.fetch()
      }
    },
  },

  methods: {
    changed: function () {
      this.$emit('changed')
    },

    fetch () {
      if (!this.accountID) {
        return
      }

      this.$http.get(this.getEndpoint).then(resp => {
        this.items = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.getEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },

    refresh () {
      if (!this.accountID) {
        return
      }

      this.$emit('refreshing')
      this.loading = true
      this.selectedCampaign = null

      this.$http.post(this.refreshEndpoint).then(resp => {
        this.fetch()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.refreshEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    refreshAdGroups () {
      if (!this.accountID) {
        return
      }

      this.$emit('refreshingAdGroups')
      this.loading = true

      this.$http.post(this.refreshAdGroupsEndpoint).then(resp => {
        this.fetch()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.refreshAdGroupsEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    }
  },

  created () {
    this.fetch()
  }
}
</script>
